import axios from "axios";
import { Navigate, Outlet } from 'react-router-dom';
import Interceptor from "./modules/interceptor";

export let CURRENCY = '₹'

export let appURL = ""
export let apiURL = ""
if (process.env.REACT_APP_MODE === 'prod') {
    console.log = function () { };
    appURL = "appshopyxa.com"
    apiURL = "shopyxa.com"
} else if (process.env.REACT_APP_MODE === 'dev') {
    appURL = "forgetaday.com"
    apiURL = appURL
} else {
    appURL = "appshopyxo.com"
    apiURL = "shopyxo.com"
}


export let base_URL = ""
if (process.env.REACT_APP_API_MODE === 'local') {
    base_URL = "http://192.168.0.18:8005/v1"
} else {
    base_URL = `https://api.accounts.${apiURL}/v1`
}


export const Api = axios.create({
    baseURL: base_URL
});

export const env = "dev"
// export const env = "live"


export const buildtype = "live"
// export const buildtype = "local"

export const isLogIn = (type) => {
    if (localStorage.getItem(type)) {
        return { isToken: true, token: localStorage.getItem(type) };
    } else {
        return { isToken: false, token: '' };
    }
};

export const Alert = (message, type) => {
    let div = document.createElement('div');
    div.classList.add("custom_alert")
    div.classList.add(type)
    div.innerHTML += `<p>${message}</p>`
    document.body.append(div)
    setTimeout(() => {
        document.body.querySelector(".custom_alert").remove()
    }, 4000);
}
export const setToken = (name, token) => {
    localStorage[name] = token;
};

export const removeToken = () => {
    localStorage.clear()
    window.location.reload(false)
};

export const PrivateRoute = () => {
    return isLogIn('token').isToken ? <Outlet /> : <Navigate to="/login" />;
}
export const PublicRoute = () => {
    return isLogIn('token').isToken ? <Navigate to="/store-login" /> : <Outlet />;
}
export const PrivateRouteSuport = () => {
    return isLogIn('support_token').isToken ? <Outlet /> : <Navigate to="/support/login" />;
}
export const PublicRouteSupport = () => {
    return isLogIn('support_token').isToken ? <Navigate to="/support" /> : <Outlet />;
}
export const PrivateRouteNetwork = () => {
    return isLogIn('network_token').isToken ? <Outlet /> : <Navigate to="/network/login" />;
}
export const PublicRouteNetwork = () => {
    return isLogIn('network_token').isToken ? <Navigate to="/network/listing" /> : <Outlet />;
}
export const PrivateRouteAdmin = () => {
    return isLogIn('admin_token').isToken ? <Outlet /> : <Navigate to="/admin/login" />;
}
export const PublicRouteAdmin = () => {
    return isLogIn('admin_token').isToken ? <Navigate to="/admin/users" /> : <Outlet />;
}

const headers = (type) => {
    if (isLogIn(type).token) {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "token": isLogIn(type).token,
        }
    } else {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
        }
    }
}
// const headerSupport = () => {
//     if (isLogIn('support_token').token) {
//         return {
//             "token": isLogIn('support_token').token,
//         }
//     } else {
//         return {
//             headers: {}
//         }
//     }
// }
// const headerNetwork = () => {
//     if (isLogIn('network_token').token) {
//         return {
//             "token": isLogIn('network_token').token,
//         }
//     } else {
//         return {
//             headers: {}
//         }
//     }
// }

function returnAxiosInstance(customHeaders = headers('token')) {
    return Interceptor({
        baseURL: base_URL,
        headers: customHeaders,
    });
}

function returnAxiosInstanceFormdata(customHeaders = headers('admin_token')) {
    return Interceptor({
        baseURL: base_URL,
        headers: customHeaders,
        type: "formdata"
    });
}
// function returnAxiosInstanceSupport(customHeaders = headers('support_token')) {
//     return Interceptor({
//         baseURL: base_URL,
//         headers: customHeaders,
//     });
// }
// function returnAxiosInstanceNetwork(customHeaders = headers('network_token')) {
//     return Interceptor({
//         baseURL: base_URL,
//         headers: customHeaders,
//     });
// }

let errorData = (err) => {
    return { error: err.response && err.response.data ? (typeof err.response.data.error == "string" ? err.response.data.error : err.response.data.error.message) : "Something went wrong" }
}
let tokenError = (err) => {
    if (err?.response?.data?.errorCode == "INVALID_TOKEN" || err?.response?.data?.errorCode == "TOKEN_EXPIRE") {
        setTimeout(() => {
            removeToken()
            window.open('/login', '_self')
        }, 2000);
    }
}
let tokenErrorNetwork = (err) => {
    if (err?.response?.data?.errorCode == "INVALID_TOKEN" || err?.response?.data?.errorCode == "TOKEN_EXPIRE") {
        setTimeout(() => {
            removeToken()
            window.open('/network/login', '_self')
        }, 2000);
    }
}
let tokenErrorAdmin = (err) => {
    if (err?.response?.data?.errorCode == "INVALID_TOKEN" || err?.response?.data?.errorCode == "TOKEN_EXPIRE") {
        setTimeout(() => {
            removeToken()
            window.open('/admin/login', '_self')
        }, 2000);
    }
}


//Handle API response
function handleResponse(response) {
    if (response.status === 200 || response.status === 201) {
        return response
    }
    if (response.status === 500 || response.status === 404) {
        return { response: { data: { error: response.data.error } }, error: true }
    }
    else {
        return { response: { data: { error: response.data.error.message } }, error: true }
    }
}

//API methods
export const GetApi = async (url, customHeaders = headers('token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.get(url).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    });
}


export const PostApi = async (url, body, customHeaders = headers('token')) => {
    // return Api.post(url, body, headers('token'))
    const axios = returnAxiosInstance(customHeaders);
    return axios.post(url, body).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PutApi = async (url, body, customHeaders = headers('token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.put(url, body).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const DeleteApi = async (url, customHeaders = headers('token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.delete(url).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}


export const GetApiSupport = (url, customHeaders = headers('support_token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.get(url).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PostApiSupport = async (url, body, customHeaders = headers('support_token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.post(url, body).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}

// 

export const GetApiNetwork = (url, customHeaders = headers('network_token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.get(url).then(handleResponse).catch((err) => {
        tokenErrorNetwork(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PostApiNetwork = async (url, body, customHeaders = headers('network_token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.post(url, body).then(handleResponse).catch((err) => {
        tokenErrorNetwork(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}

export const PutApiNetwork = async (url, body, customHeaders = headers('network_token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.put(url, body).then(handleResponse).catch((err) => {
        tokenErrorNetwork(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}

//Admin apis
export const GetApiAdmin = (url, customHeaders = headers('admin_token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.get(url).then(handleResponse).catch((err) => {
        tokenErrorAdmin(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const PostApiAdmin = async (url, body, customHeaders = headers('admin_token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.post(url, body).then(handleResponse).catch((err) => {
        tokenErrorAdmin(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}

export const PutApiAdmin = async (url, body, customHeaders = headers('admin_token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.put(url, body).then(handleResponse).catch((err) => {
        tokenErrorAdmin(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}
export const DeleteApiAdmin = async (url, customHeaders = headers('admin_token')) => {
    const axios = returnAxiosInstance(customHeaders);
    return axios.delete(url).then(handleResponse).catch((err) => {
        tokenError(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}


export const PostApiAdminFormdata = async (url, body, customHeaders = headers()) => {
    const axios = returnAxiosInstanceFormdata(customHeaders);
    return axios.post(url, body).then(handleResponse).catch((err) => {
        // tokenErrorAdmin(err)
        return {
            error: true,
            response: {
                data: errorData(err)
            },
            res: err.response || "Something went wrong"
        }
    })
}